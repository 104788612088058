import { post, get, downFile } from 'common/request'

export default {
//根据ID查询
getById: id => get(`/scRedProperty/getById?id=${id}`),
//新增
add: args => post('/scRedProperty/add', args),
//分页查询
list: args => get('/scRedProperty/list', args),
 //根据ID修改
 update: args => post(`/scRedProperty/update`,args),
 //根据ID删除
deleteById: id => post(`/scRedProperty/delete?id=${id}`),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
}
